import React from 'react';
import { styled } from '@mui/material/styles';
import { FormHelperText, TextField } from '@mui/material';

const CustomTextField = styled(({ errorMessage, ...props }: any) => (
  <>
    <TextField {...props} />
    {props.error && !props.disabled && <FormHelperText error>{errorMessage}</FormHelperText>}
  </>
))(({ theme }) => ({
  '& .MuiOutlinedInput-input::-webkit-input-placeholder': {
    color: theme.palette.text.secondary,
    opacity: '0.8',
  },
  '& .MuiOutlinedInput-input.Mui-disabled::-webkit-input-placeholder': {
    color: theme.palette.text.secondary,
    opacity: '1',
  },
  '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[200],
  },
}));

export default React.memo(CustomTextField);
