export const ROUTES = {
  HOME: '/',
  BRAND: '/brand',
  BRAND_DETAIL: '/brand/:brandId',
  ROLE: {
    LIST: '/role',
    ROLE_DETAIL: '/role/:roleId',
  },
  EMPLOYEE_PERMISSION: '/brand/:brandId/employee/:employeeId/permission/:accountId',
  ERROR: {
    NOT_FOUND: '/error/404',
    FORBIDDEN: '/error/403',
  },
  AUTH: {
    LOGIN: '/auth',
  },
};
