import React, { lazy } from 'react';

import { ROUTES } from 'constants/router';
import Loadable from 'layouts/full/shared/loadable/Loadable';
import { Navigate } from 'react-router-dom';
import EmployeePermissionPage from 'views/brand/EmployeePermissionPage';
import RolePermissionPage from 'views/role/RolePermissionPage';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('layouts/blank/BlankLayout')));

/* ****Pages***** */
const BrandPage = Loadable(lazy(() => import('views/brand/BrandPage')));
const BrandDetailPage = Loadable(lazy(() => import('views/brand/BrandDetailPage')));
const LoginPage = Loadable(lazy(() => import('views/auth/LoginPage')));

const RolePage = Loadable(lazy(() => import('views/role/RolePage')));

/* ****Error Pages***** */
const Error404Page = Loadable(lazy(() => import('views/error/Error404Page')));
const Error403Page = Loadable(lazy(() => import('views/error/Error403Page')));

const Router = [
  {
    path: ROUTES.HOME,
    element: <FullLayout />,
    children: [
      { path: ROUTES.HOME, element: <Navigate to={ROUTES.BRAND} /> },
      { path: ROUTES.BRAND, exact: true, element: <BrandPage /> },
      { path: ROUTES.BRAND_DETAIL, exact: true, element: <BrandDetailPage /> },
      { path: ROUTES.EMPLOYEE_PERMISSION, exact: true, element: <EmployeePermissionPage /> },

      {
        path: ROUTES.ROLE.LIST,
        exact: true,
        element: <RolePage />,
      },
      { path: ROUTES.ROLE.ROLE_DETAIL, exact: true, element: <RolePermissionPage /> },

      { path: '*', element: <Navigate to={ROUTES.ERROR.NOT_FOUND} /> },
    ],
  },
  {
    path: ROUTES.HOME,
    element: <BlankLayout />,
    children: [
      // NOTE: auth route
      { path: ROUTES.AUTH.LOGIN, element: <LoginPage /> },

      // NOTE: error route
      { path: ROUTES.ERROR.NOT_FOUND, element: <Error404Page /> },
      { path: ROUTES.ERROR.FORBIDDEN, element: <Error403Page /> },
      { path: '*', element: <Navigate to={ROUTES.ERROR.NOT_FOUND} /> },
    ],
  },
];

export default Router;
