/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

type Props = {
  description?: string;
  children: JSX.Element | JSX.Element[];
  title?: string;
  isFullHeight?: boolean;
};

function PageContainer({ title, description, children, isFullHeight = false }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t(title || '')}</title>
        <meta name="description" content={t(description || '') || ''} />
      </Helmet>
      <Box sx={{ marginTop: 0 }}>{children}</Box>
    </div>
  );
}

export default React.memo(PageContainer);
