import { createAsyncThunk } from '@reduxjs/toolkit';
import { login } from 'services/auth.service';
import { LoginReq } from 'types/auth.type';

import { isSuccessCode } from 'utils/common';

export const loginAction = createAsyncThunk(
  'auth/loginAction',
  async (payload: { data: LoginReq; callback?: () => void }) => {
    const response = await login(payload.data);

    if (response.data.code && isSuccessCode(response.data.code)) {
      setTimeout(() => {
        payload.callback && payload.callback();
      }, 500);
    }

    return response.data;
  },
);
