import * as React from 'react';
import { DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogWrapper from 'components/dialog/DialogWrapper';
import { isEmpty } from 'lodash';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  message?: string;
};

function ConfirmModal({ isOpen = false, onClose, onSubmit, message }: Props) {
  const { t } = useTranslation();

  return (
    <DialogWrapper
      title="confirm"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      isFullWidth={false}
    >
      <DialogContentText id="alert-dialog-description">
        {!isEmpty(message)
          ? `${t('common-message.ms5')}${t(String(message))} ${t('common-message.ms6')}`
          : t('common-message.ms4')}
      </DialogContentText>
    </DialogWrapper>
  );
}

export default React.memo(ConfirmModal);
