export const ROW_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100];

export const DEFAULT_LIMIT = ROW_PER_PAGE_OPTIONS[1];

export const DATE_FORMAT = 'DD-MM-YYYY';

export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm:ss';

export const PERIOD_FORMAT = 'MM-YYYY';

export const EMPTY_CONTENT = '-';

export const DEFAULT_DEBOUNCE = 500;

export const PAYOS_ID = 1;

export const MAX_UPLOAD_LIMIT = 5;

export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

export const APP_NAME = 'MORA ADMIN';
