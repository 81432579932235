import * as React from 'react';

import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  Zoom,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Theme } from '@emotion/react';

type Props = {
  title?: string;
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  children?: React.ReactNode;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  isFullWidth?: boolean;
  isAction?: boolean;
  isDisable?: boolean;
  titleSx?: SxProps<Theme>;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

function DialogWrapper({
  title = '',
  isOpen = false,
  primaryButtonLabel = '',
  secondaryButtonLabel = '',
  onClose,
  onSubmit,
  children,
  isFullWidth = true,
  isAction = true,
  titleSx,
  isDisable = false,
}: Props) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth={isFullWidth}
    >
      <DialogTitle id="alert-dialog-title" sx={{ ...titleSx }}>
        {t(title)}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: '12px',
        }}
      >
        {children}
      </DialogContent>
      {isAction && (
        <DialogActions>
          <Button variant="contained" color="error" onClick={onClose}>
            {t(secondaryButtonLabel || 'cancel')}
          </Button>
          <Button variant="contained" onClick={onSubmit} disabled={isDisable} autoFocus>
            {t(primaryButtonLabel || 'agree')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default React.memo(DialogWrapper);
