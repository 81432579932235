import { CommonReq, PagingReq } from 'types/common.type';
import { centralGW } from './axios.service';

export const getEmployeesInBrand = async (payload: CommonReq & PagingReq) => {
  const { branchId, page, limit } = payload;

  return centralGW.get(`/api/v1/employee/branch/${branchId}`, {
    isDisableToast: true,
    params: {
      page,
      limit,
    },
  });
};

export const getEmployeesDetail = async (payload: CommonReq) => {
  const { employeeId } = payload;

  return centralGW.get(`/api/v1/employee/${employeeId}/detail`, {
    isDisableToast: true,
  });
};
