import { DATE_FORMAT, EMPTY_CONTENT } from 'constants/common';
import { PERMISSION } from 'constants/permission';
import _, { isNull, isUndefined } from 'lodash';
import moment from 'moment';

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent,
);

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',');
  const mime = (arr[0].match(/:(.*?);/) || [])[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const isSuccessCode = (code: number) => {
  return String(code).length === 4;
};

export const showData = (data: any) => {
  if (isNull(data) || isUndefined(data) || data.length === 0) return EMPTY_CONTENT;
  else return data;
};

export const getPermissions = () => {
  /**TODO: Dummy */
  return [PERMISSION.VIEW_DASHBOARD];
};

export const isAllow = (permission: string) => {
  const listPermission = getPermissions();
  const existed = listPermission.find((value) => value === permission);

  return !isNull(existed) && !isUndefined(existed);
};

export const formatPrice = (price: number | string) => {
  const numericString = String(price).replace(/[^0-9]/g, '');

  const numericArray = numericString.split('');
  const reversedArray = numericArray.reverse();
  const formattedArray = [];
  for (let i = 0; i < reversedArray.length; i++) {
    if (i > 0 && i % 3 === 0) {
      formattedArray.push('.');
    }
    formattedArray.push(reversedArray[i]);
  }
  const formattedString = formattedArray.reverse().join('');

  return formattedString;
};

export const formatNumber = (input: string): number => {
  const numericString = input.replace(/[^\d]/g, '');
  const numberValue = Number(numericString);

  return numberValue > 0 ? numberValue : 0;
};

export const compareObject = (a: any, b: any) => {
  return _.isEqual(a, b);
};

export const truncateString = (inputString: string | undefined, maxLength: number) => {
  if (Number(inputString?.length) > maxLength) {
    return inputString?.substring(0, maxLength) + '...';
  } else {
    return inputString;
  }
};

export const cleanSearchString = (inputString: string) => {
  return inputString.trim();
};

export const formatDate = (date: Date | string | undefined, formatDate?: string) => {
  if (!date) {
    return EMPTY_CONTENT;
  }

  return moment(date).format(formatDate ?? DATE_FORMAT);
};

export const isDateValid = (date: Date | string): boolean => {
  const inputMoment = moment(date);
  const dateNow = moment();

  return inputMoment.isAfter(dateNow);
};

export const parseStringToDate = (dateString: string | undefined): Date | null => {
  if (!dateString) {
    return null;
  }

  const dateParts = dateString.split('-');

  if (dateParts.length !== 3) {
    return null;
  }

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const year = parseInt(dateParts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return null;
  }

  const formattedDate = new Date(year, month, day);

  if (isNaN(formattedDate.getTime())) {
    return null;
  }

  return formattedDate;
};

export const getDateTime = (timestamp: string | Date) => {
  const currentTime = moment();
  const time = moment(timestamp);
  if (currentTime.diff(time, 'days') === 0) {
    return time.format('HH:mm');
  } else {
    return time.format('HH:mm DD-MM-YYYY ');
  }
};

export const getPathName = () => {
  return window.location.pathname.replace(/\/\d+/g, '');
};

export const svgElementToBase64 = (svgElement: SVGElement) => {
  const svgXML = new XMLSerializer().serializeToString(svgElement);
  const base64EncodedSVG = btoa(unescape(encodeURIComponent(svgXML)));

  return 'data:image/svg+xml;base64,' + base64EncodedSVG;
};
