import React, { useRef } from 'react';
import DialogWrapper from 'components/dialog/DialogWrapper';
import { useTranslation } from 'react-i18next';
import { ModalActionType } from 'types/common.type';
import AssignPermissionByRoleForm from 'components/forms/AssignPermissionByRoleForm';
import { RoleType } from 'types/brand.type';

interface Props extends ModalActionType {
  data: any;
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  listRole?: RoleType[];
}

const AssignPermissionByRoleModal = ({
  data,
  isOpen,
  listRole,
  onClose,
  onChange,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const submitRef = useRef<{ handleSubmitAssignPermissionByRole: () => void }>();

  const handleSubmitAssignPermissionByRole = () => {
    submitRef.current?.handleSubmitAssignPermissionByRole();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <DialogWrapper
      title={String(t('assign-permission-by-role'))}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmitAssignPermissionByRole}
      isFullWidth={true}
    >
      <AssignPermissionByRoleForm
        ref={submitRef}
        data={data}
        onChange={onChange}
        onSubmit={onSubmit}
        listRole={listRole}
      />
    </DialogWrapper>
  );
};

export default React.memo(AssignPermissionByRoleModal);
