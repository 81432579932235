import 'simplebar/dist/simplebar.min.css';

import React, { useEffect, useRef, useState } from 'react';

import SimpleBar from 'simplebar-react';

import { styled, SxProps } from '@mui/system';

interface PropsType {
  children: React.ReactElement | React.ReactNode;
  sx?: SxProps;
}
const SimpleBarStyle = styled(SimpleBar)(() => ({
  maxHeight: '100%',
  '& .simplebar-content': {
    userSelect: 'none',
  },
}));

function Scrollbar(props: PropsType) {
  const { children, sx, ...other } = props;

  const simpleBarRef = useRef<SimpleBar | null>(null);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (
        simpleBarRef?.current &&
        simpleBarRef.current.getScrollElement().contains(event.target as Node)
      ) {
        setStartX(event.clientX);
        setIsDragging(true);
      }
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (isDragging && simpleBarRef?.current) {
        const scrollElement = simpleBarRef.current.getScrollElement();
        if (scrollElement) {
          const delta = startX - event.clientX;
          scrollElement.scrollLeft += delta;
          setStartX(event.clientX);
        }
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [simpleBarRef, startX, isDragging]);

  return (
    <SimpleBarStyle sx={sx} {...other} ref={simpleBarRef}>
      {children}
    </SimpleBarStyle>
  );
}

export default React.memo(Scrollbar);
