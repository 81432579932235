import React from 'react';
import { SpinnerRoundFilled } from 'spinners-react';
import './spinner.css';
import { useTheme } from '@mui/material';
import ReactDOM from 'react-dom';

function Spinner(props: { isLoading: boolean }) {
  const theme = useTheme();

  return ReactDOM.createPortal(
    <div className="fallback-spinner" style={{ display: props.isLoading ? 'flex' : 'none' }}>
      <div className="loading component-loader">
        <SpinnerRoundFilled
          size={120}
          thickness={100}
          speed={100}
          color={theme.palette.primary.dark}
        />
      </div>
    </div>,
    document.body,
  );
}
export default React.memo(Spinner);
