import React, { forwardRef, useImperativeHandle, useState } from 'react';

import TextInput from 'components/shared/TextInput';
import { JOI } from 'constants/joi';
import { createSchema, validateSchema } from 'utils/joi';

import { PermissionCreateFormType } from 'types/permission.type';
import { Grid } from '@mui/material';
import AutoComplete from 'components/shared/AutoComplete';
import { showData } from 'utils/common';
import { IconAccessible } from '@tabler/icons-react';

interface Props {
  data: PermissionCreateFormType;
  onChange: (key: string, value: string) => void;
  onSubmit?: () => void;
  isDisable?: boolean;
  listPermission?: any[];
}

const initError = {
  permissionName: '',
  permissionKey: '',
  permissionParentId: '',
};

const PermissionForm = forwardRef(
  ({ data, onChange, onSubmit, listPermission = [], isDisable = false }: Props, ref) => {
    const handleChange = (key: string) => (value: string) => {
      onChange(key, value);
    };

    const schema = createSchema({
      permissionName: JOI.REQUIRE,
      permissionKey: JOI.REQUIRE,
      permissionParentId: JOI.ALLOW,
    });

    const [permissionError, setPermissionError] = useState<PermissionCreateFormType>(initError);

    const handleSubmitPermission = () => {
      const resError = validateSchema(schema, data);
      setPermissionError(resError.errorMessage);
      if (!resError.isError) {
        onSubmit && onSubmit();
      }
    };

    useImperativeHandle(ref, () => ({
      handleSubmitPermission,
    }));

    return (
      <Grid item xs={12}>
        <Grid container spacing={'12px'}>
          <Grid item xs={12}>
            <TextInput
              isRequired
              title="Khóa định danh của quyền"
              Icon={IconAccessible}
              value={data.permissionKey?.toLocaleUpperCase()}
              placeholder="G_GET_USER_LIST..."
              onChange={handleChange('permissionKey')}
              errorMessage={permissionError.permissionKey}
              isDisable={isDisable}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              isRequired
              title="Tên quyền"
              Icon={IconAccessible}
              value={data.permissionName}
              placeholder="Tên quyền..."
              onChange={handleChange('permissionName')}
              errorMessage={permissionError.permissionName}
              isDisable={isDisable}
            />
          </Grid>
          <Grid item xs={12}>
            <AutoComplete
              title={'Thuộc quyền cha'}
              options={listPermission.map((permission) => ({
                label: showData(
                  String(
                    permission.permissionParentId
                      ? ` ○ ${permission.permissionName}`
                      : permission.permissionName,
                  ),
                ),
                value: String(permission.id),
              }))}
              Icon={IconAccessible}
              value={String(data.permissionParentId)}
              placeHolder={'Chọn quyền cha...'}
              onChange={handleChange('permissionParentId')}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  },
);

export default React.memo(PermissionForm);
