import { FC, useMemo } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { ThemeSettings } from './theme/Theme';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import Toast from 'components/shared/Toast';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import { closeToast, commonSelector } from 'store/common';
import { useDispatch, useSelector } from 'store/Store';
import './index.scss';

const App: FC = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const { isLoading, isShowToast, toastContent } = useSelector(commonSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const parseMessage = useMemo(() => {
    const { code, message, status, description } = toastContent;
    if (code === 4) {
      return {
        code: description?.code,
        message: description?.message,
        type: 'service-error',
      };
    } else {
      return {
        code,
        message,
        type: status,
      };
    }
  }, [toastContent]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop>{routing}</ScrollToTop>
      <Spinner isLoading={isLoading} />
      <Toast
        status={toastContent?.status || 'info'}
        isOpen={isShowToast}
        code={parseMessage?.code}
        onClose={() => dispatch(closeToast())}
        message={t(`${parseMessage?.type}.${parseMessage?.code}`) || ''}
      />
    </ThemeProvider>
  );
};

export default App;
