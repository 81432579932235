import { CommonReq } from 'types/common.type';
import { centralGW } from './axios.service';

export const getRole = async () => {
  return centralGW.get(`/api/v1/auth/roles`, {
    isDisableToast: true,
  });
};
export const updateRoleFEPermission = async (payload: CommonReq & { permissionIds: string[] }) => {
  const { roleId, permissionIds } = payload;

  return centralGW.post(`/api/v1/auth/role/${roleId}/fe-permissions/reference`, { permissionIds });
};

export const getRoleFEPermission = async (payload: CommonReq) => {
  const { roleId } = payload;

  return centralGW.get(`/api/v1/auth/role/${roleId}/fe-permissions/reference`, {
    isDisableToast: true,
  });
};
