import { CommonReq } from 'types/common.type';
import { centralGW } from './axios.service';
import { LoginReq } from 'types/auth.type';

export const login = async (payload: LoginReq) => {
  return centralGW.post('/api/v1/auth/login', payload);
};

export const getProfile = async () => {
  return centralGW.get('/api/v1/auth/profile', { isDisableToast: true });
};

export const getListPermissions = async () => {
  return centralGW.get('/api/v1/auth/permissions', {
    isDisableToast: true,
  });
};

export const getListRoles = async () => {
  return centralGW.get('/api/v1/auth/roles');
};

export const getEmployeeFEPermission = async (payload: CommonReq) => {
  const { accountId } = payload;

  return centralGW.get(`/api/v1/auth/account/${accountId}/fe-permissions`, {
    isDisableToast: true,
  });
};

export const updateEmployeeFEPermission = async (
  payload: CommonReq & { permissionIds: string[] },
) => {
  const { accountId, permissionIds } = payload;

  return centralGW.post(`/api/v1/auth/account/${accountId}/fe-permissions`, { permissionIds });
};
