import * as React from 'react';

import CustomFormLabel from 'components/theme-elements/CustomFormLabel';
import CustomTextField from 'components/theme-elements/CustomTextField';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  InputAdornment,
  Typography,
} from '@mui/material';
import { TablerIconsProps } from '@tabler/icons-react';

type Props = {
  isRequired?: boolean;
  isDisable?: boolean;
  title?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  disableClear?: boolean;
  options?: {
    label: string;
    value: string;
  }[];
  placeHolder?: string;
  Icon?: React.ComponentType<TablerIconsProps>;
  errorMessage?: string;
  autoSelect?: boolean;
};

function AutoComplete({
  options = [],
  value,
  onChange,
  errorMessage,
  Icon,
  placeHolder = '',
  title = '',
  isRequired = false,
  isDisable = false,
  disableClear = false,
  autoSelect = true,
}: Props) {
  const { t } = useTranslation();

  const getLabel = React.useCallback(
    (value: string) => {
      const hashList = _.mapKeys(options, 'value');

      return hashList[value]?.label || '';
    },
    [options],
  );

  const listOption = React.useMemo(() => {
    return [''].concat(options.map((ele) => ele.value));
  }, [options]);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: string | null) => {
    onChange && onChange(newValue || '');
  };

  const handelRenderOption = (props: React.HTMLAttributes<HTMLLIElement>, value: string) => {
    return (
      value && (
        <Box
          component="li"
          value={value}
          sx={{ '& > span': { mr: '10px' } }}
          {...props}
          key={value}
        >
          <Typography>{getLabel(value)}</Typography>
        </Box>
      )
    );
  };

  const handleRenderInput = React.useCallback(
    (payload: AutocompleteRenderInputParams) => {
      return (
        <CustomTextField
          {...payload}
          placeholder={String(t(placeHolder))}
          aria-label={t(placeHolder)}
          autoComplete="off"
          InputProps={{
            ...payload.InputProps,
            style: { padding: '5px 50px 5px 5px' },
            startAdornment: (
              <InputAdornment position="start" sx={{ paddingLeft: '10px', marginRight: '10px' }}>
                {Icon && <Icon size="20px" />}
              </InputAdornment>
            ),
          }}
          error={Boolean(errorMessage)}
          errorMessage={t(`validate-message.${errorMessage}`)}
        />
      );
    },
    [Icon, errorMessage, placeHolder, t],
  );

  return (
    <Box width="100%">
      {title && (
        <CustomFormLabel className={`${isRequired ? 'required' : ''}`} sx={{ mt: '0px' }}>
          {t(title)}
        </CustomFormLabel>
      )}
      <Autocomplete
        size="medium"
        value={value}
        options={listOption}
        disableClearable={disableClear}
        onChange={handleChange}
        getOptionLabel={getLabel}
        renderOption={handelRenderOption}
        renderInput={handleRenderInput}
        disabled={isDisable}
        autoHighlight
        autoSelect={autoSelect}
      />
    </Box>
  );
}

export default React.memo(AutoComplete);
