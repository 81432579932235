import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import { combineReducers } from 'redux';

import { configureStore } from '@reduxjs/toolkit';

import AuthReducer from './auth/reducer';
import CustomizerReducer from './customizer/reducer';
import CommonReducer from './common/reducer';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    auth: AuthReducer,
    common: CommonReducer,
  },
});

const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  auth: AuthReducer,
  common: CommonReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const { dispatch } = store;
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;

export default store;
