import React, { useEffect, useRef } from 'react';
import { FormHelperText, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomOutlinedInput = styled(
  ({ errorMessage, isDisable, error, autoFocus, ...props }: any) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (inputRef.current) {
        autoFocus && inputRef.current.click();
      }
    }, [autoFocus]);

    return (
      <>
        <OutlinedInput ref={inputRef} error={error} {...props} disabled={isDisable} />
        {error && !isDisable && <FormHelperText error>{errorMessage}</FormHelperText>}
      </>
    );
  },
)(({ theme }) => ({
  '& .MuiOutlinedInput-input::-webkit-input-placeholder': {
    color: theme.palette.text.secondary,
    opacity: '0.8',
  },

  '& .MuiTypography-root': {
    color: theme.palette.text.secondary,
  },

  '& .MuiInputBase-input': {
    padding: ' 7.5px 4px 7.5px 6px',
  },

  '& .MuiOutlinedInput-input.Mui-disabled::-webkit-input-placeholder': {
    color: theme.palette.text.secondary,
    opacity: '1',
  },
}));

export default React.memo(CustomOutlinedInput);
