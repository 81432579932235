/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Checkbox, FormControlLabel, IconButton, Stack, Tooltip } from '@mui/material';
import { IconShieldCheck, IconShieldPlus, IconTrashXFilled } from '@tabler/icons-react';
import {
  IconPlus,
  IconSquareRoundedMinusFilled,
  IconSquareRoundedPlus,
  IconTrash,
  IconTrashFilled,
} from '@tabler/icons-react';
import PageContainer from 'components/container/PageContainer';
import AssignPermissionByRoleModal from 'components/modals/AssignPermissionByRoleModal';
import ConfirmModal from 'components/modals/ConfirmModal';
import PermissionModal from 'components/modals/PermissionModal';
import BaseCard from 'components/shared/BaseCard';
import { useDisclosure } from 'hooks';
import _, { isEmpty } from 'lodash';
import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { getEmployeeFEPermission, updateEmployeeFEPermission } from 'services/auth.service';
import { getEmployeesDetail } from 'services/employee.service';
import {
  createFEPermission,
  deleteFEPermission,
  getListFEPermissions,
} from 'services/permission.service';
import { getRole } from 'services/role.service';
import { EmployeePermissionRes, PermissionInfo } from 'types/auth.type';
import { EmployeeInfo } from 'types/employee.type';
import { PermissionCreateFormType } from 'types/permission.type';
import { isSuccessCode } from 'utils/common';

function EmployeePermissionContainer() {
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState<PermissionInfo[]>([]);
  const [closedList, setClosedList] = useState<string[]>([]);
  const [employeeInfo, setEmployeeInfo] = useState<EmployeeInfo>();
  const [employeePermission, setEmployeePermission] = useState<EmployeePermissionRes>();
  const [selectedPermission, setSelectedPermission] = useState<string[]>([]);
  const [hoveredItemId, setHoveredItemId] = useState<string>('');
  const { accountId, employeeId } = useParams();

  const {
    isOpen: isOpenCreatePermission,
    onClose: onCloseCreatePermission,
    onOpen: onOpenCreatePermission,
  } = useDisclosure();
  const {
    isOpen: isOpenDeletePermission,
    onClose: onCloseDeletePermission,
    onOpen: onOpenDeletePermission,
  } = useDisclosure();

  const {
    isOpen: isOpenAssignByRolePermission,
    onClose: onCloseAssignByRolePermission,
    onOpen: onOpenAssignByRolePermission,
  } = useDisclosure();

  const getDataEmployeeFEPermission = useCallback(async () => {
    if (accountId) {
      const response = await getEmployeeFEPermission({
        accountId: Number(accountId),
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        setEmployeePermission(data);
        setSelectedPermission(data.permissionIds);
      }
    }
  }, [accountId]);

  useEffect(() => {
    getDataEmployeeFEPermission();
  }, [getDataEmployeeFEPermission]);

  const getPermissionList = async () => {
    const response = await getListFEPermissions();
    const { data, code } = response.data;
    if (isSuccessCode(code)) {
      setPermissions(data);
    }
  };
  useEffect(() => {
    getPermissionList();
  }, []);

  useEffect(() => {
    const getData = async () => {
      if (employeeId) {
        const response = await getEmployeesDetail({
          employeeId: Number(employeeId),
        });
        const { data, code } = response.data;
        if (isSuccessCode(code)) {
          setEmployeeInfo(data);
        }
      }
    };
    getData();
  }, [employeeId]);

  // NOTE: create permission
  const permissionFormInitial = {
    permissionName: '',
    permissionKey: 'SB_',
    permissionParentId: '',
  };
  const assignByRoleFormInitial = {
    roleId: '',
  };

  const [formPermissionData, setFormPermissionData] =
    useState<PermissionCreateFormType>(permissionFormInitial);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedPermissionId, setSelectedPermissionId] = useState('');
  const [listRole, setListRole] = useState([]);
  const [formAssignByRoleData, setFormAssignByRoleData] = useState(assignByRoleFormInitial);

  useEffect(() => {
    const getData = async () => {
      const response = await getRole();
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        setListRole(data);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const listPermissionIds = permissions.map((permission) => String(permission.id));
    const isCheckAllList = _.isEqual(
      (listPermissionIds ? listPermissionIds : []).sort(),
      selectedPermission.filter((item, index) => selectedPermission.indexOf(item) === index).sort(),
    );
    if (isCheckAllList) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  }, [permissions, selectedPermission]);

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const listPermissionIds = permissions.map((permission) => String(permission.id));

    if (checked) {
      setSelectedPermission(selectedPermission.concat(listPermissionIds).flat());
    } else {
      setSelectedPermission([]);
    }
    setIsCheckAll(checked);
  };

  const permissionStructural = useMemo(() => {
    const level1s = permissions.filter((ele) => ele.permissionParentId === null);
    const result: (PermissionInfo & {
      children: (PermissionInfo & { subChild: PermissionInfo[] })[];
    })[] = [];

    // map tab list
    level1s.forEach((level1) => {
      const item = { ...level1 } as any;
      item.children = permissions.filter((ele) => ele.permissionParentId == item.id);

      // map sub tab list
      item.children.forEach((level2: any) => {
        const subChildPermissions = permissions.filter(
          (ele) => ele.permissionParentId == level2.id,
        );
        level2.subChild = subChildPermissions.map((item) => ({ ...item, isSubChild: true }));
      });
      result.push(item);
    });

    return result;
  }, [permissions]);

  const onChangeExpanded = (id: string) => () => {
    if (closedList.includes(id)) {
      setClosedList(closedList.filter((ele) => ele != id));
    } else {
      setClosedList(closedList.concat([id]));
    }
  };

  const handleUpdatePermission = async () => {
    const response = await updateEmployeeFEPermission({
      accountId: Number(accountId),
      permissionIds: selectedPermission.filter(
        (item, index) => selectedPermission.indexOf(item) === index,
      ),
    });
    const { code } = response.data;
    if (code) {
      getDataEmployeeFEPermission();
    }
  };

  const isDisabledUpdate = useMemo(() => {
    return _.isEqual(
      (employeePermission && employeePermission?.permissionIds
        ? employeePermission?.permissionIds
        : []
      ).sort(),
      selectedPermission.filter((item, index) => selectedPermission.indexOf(item) === index).sort(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeePermission, selectedPermission, isCheckAll]);

  // NOTE:

  const handleSelectPermission =
    (permissionId: string) => (_e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const parentId =
        permissions.find((ele) => ele.id == permissionId)?.permissionParentId ?? permissionId;

      const grandParent = (permissions.find((ele) => ele.id == parentId)?.permissionParentId ??
        parentId) as string;
      const childPermission = permissions
        .filter((ele) => ele.permissionParentId == permissionId)
        .map((ele) => String(ele.id));
      const subChildPermission = permissions
        .filter((item) => childPermission.includes(String(item.permissionParentId)))
        .map((ele) => String(ele.id));
      if (checked) {
        if (childPermission?.length > 0) {
          setSelectedPermission(
            selectedPermission
              .concat(childPermission, subChildPermission, grandParent, permissionId)
              .flat(),
          );
        } else {
          setSelectedPermission(
            selectedPermission.concat([permissionId, String(grandParent), String(parentId)]),
          );
        }
      } else {
        const concatArray = childPermission.concat(subChildPermission);

        // children list by parent
        const childrenListByParentId = permissions
          .filter((ele) => ele.permissionParentId == parentId)
          .map((ele) => String(ele.id));
        const childrenListSelected = childrenListByParentId.filter((child) =>
          selectedPermission.includes(child),
        );

        // grand parent list
        const childrenListByGrandParentId = permissions
          .filter(
            (ele) =>
              ele.permissionParentId == grandParent && selectedPermission.includes(String(ele.id)),
          )
          .map((ele) => String(ele.id));

        const filterDuplicateChildrenListSelected = childrenListSelected.filter(
          (item, index) => childrenListSelected.indexOf(item) === index,
        );
        if (childPermission?.length > 0) {
          if (
            filterDuplicateChildrenListSelected.length === 1 &&
            childrenListByGrandParentId.length === 1
          ) {
            setSelectedPermission(
              selectedPermission.filter(
                (ele) => ![...concatArray, grandParent, permissionId].includes(ele),
              ),
            );
          } else {
            setSelectedPermission(
              selectedPermission.filter((ele) => ![...concatArray, permissionId].includes(ele)),
            );
          }
        } else {
          if (filterDuplicateChildrenListSelected.length === 1) {
            if (childrenListByGrandParentId.length === 1) {
              setSelectedPermission(
                selectedPermission.filter(
                  (ele) => ele !== permissionId && ele !== parentId && ele !== grandParent,
                ),
              );
            } else {
              setSelectedPermission(
                selectedPermission.filter((ele) => ele !== permissionId && ele !== parentId),
              );
            }
          } else {
            setSelectedPermission(selectedPermission.filter((ele) => ele !== permissionId));
          }
        }
      }
    };

  const handleOpenModalPermission = () => {
    onOpenCreatePermission();
    setFormPermissionData(permissionFormInitial);
  };
  const handleChangeCreatePermission = (key: string, value: string | boolean) => {
    setFormPermissionData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleCreatePermissionSubmit = async () => {
    const response = await createFEPermission({
      permissionName: formPermissionData.permissionName,
      permissionKey: formPermissionData.permissionKey?.toUpperCase(),
      permissionParentId: Number(formPermissionData.permissionParentId),
      isActive: true,
    });
    const { code } = response.data;
    if (isSuccessCode(code)) {
      onCloseCreatePermission();
      getPermissionList();
    }
  };
  const generatePermissionList = useMemo(() => {
    return permissionStructural
      .filter((item) => item?.permissionKey?.startsWith('SB'))
      .reduce(
        (
          acc: (
            | PermissionInfo
            | (PermissionInfo & {
                children: (PermissionInfo & { subChild: PermissionInfo[] })[];
              })
          )[],
          item,
        ) => {
          if (item.children.length > 0) {
            return [...acc, item, ...item.children];
          } else {
            return [...acc, item];
          }
        },
        [],
      );
  }, [permissionStructural]);

  const handleOpenDeletePermission = (permissionId: string) => () => {
    onOpenDeletePermission();
    setSelectedPermissionId(permissionId);
  };
  const handleDeletePermission = async () => {
    const response = await deleteFEPermission({
      fePermissionId: String(selectedPermissionId),
    });
    const { code } = response.data;
    if (isSuccessCode(code)) {
      onCloseDeletePermission();
      getPermissionList();
      setSelectedPermissionId('');
    }
  };

  const handleOpenModalAssignByRole = () => {
    onOpenAssignByRolePermission();
    setFormAssignByRoleData(assignByRoleFormInitial);
  };
  const handleChangeAssignPermissionByRole = (key: string, value: string | boolean) => {
    setFormAssignByRoleData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleAssignByRoleSubmit = async () => {
    console.log(formAssignByRoleData);

    // const response = await createFEPermission({
    //   permissionName: formPermissionData.permissionName,
    //   permissionKey: formPermissionData.permissionKey?.toUpperCase(),
    //   permissionParentId: Number(formPermissionData.permissionParentId),
    //   isActive: true,
    // });
    // const { code } = response.data;
    // if (isSuccessCode(code)) {
    //   onCloseCreatePermission();
    //   getPermissionList();
    // }
  };

  return (
    <PageContainer title="brand">
      <Box display="flex" flexDirection="column" gap="24px">
        <BaseCard title={String(employeeInfo?.accountDetail.fullName)}>
          <p>Thông tin cá nhân</p>
        </BaseCard>
        <Stack direction={'column'} gap="24px">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '12px' }}>
            <Box>
              <FormControlLabel
                label={'Check all'}
                style={{
                  width: 'fit-content',
                }}
                control={
                  <>
                    <Checkbox checked={isCheckAll} onChange={handleCheckAll} />
                  </>
                }
                componentsProps={{
                  typography: {
                    fontWeight: 'bold',
                    fontSize: '16px',
                    marginTop: '6px',
                    textTransform: 'uppercase',
                  },
                }}
              />
              <Button
                variant="contained"
                disabled={isDisabledUpdate}
                onClick={handleUpdatePermission}
              >
                Cập nhật
              </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '12px' }}>
              <Button
                variant="outlined"
                startIcon={<IconShieldPlus size={'21px'} />}
                onClick={handleOpenModalAssignByRole}
              >
                Thêm quyền theo vai trò
              </Button>
              <Button
                variant="contained"
                startIcon={<IconPlus size={'21px'} />}
                onClick={handleOpenModalPermission}
              >
                Thêm quyền
              </Button>
            </Box>
          </Box>
          <BaseCard title={'Quyền'}>
            <Box
              display="flex"
              flexDirection="column"
              maxHeight="calc(100vh - 350px)"
              overflow="auto"
            >
              {permissionStructural.map((permission) => {
                return (
                  <Fragment key={permission.id}>
                    <Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        gap="0"
                        position="relative"
                        paddingLeft="32px"
                        onMouseEnter={() => setHoveredItemId(String(permission.id))}
                        onMouseLeave={() => setHoveredItemId('')}
                      >
                        {permission.children.length > 0 && (
                          <Box
                            style={{ cursor: 'pointer' }}
                            padding="0"
                            left="0"
                            top={'0'}
                            marginTop="7px"
                            width="24px"
                            position="absolute"
                            onClick={onChangeExpanded(String(permission.id))}
                          >
                            {closedList.includes(String(permission.id)) ? (
                              <IconSquareRoundedMinusFilled height="24px" width="24px" />
                            ) : (
                              <IconSquareRoundedPlus height="24px" width="24px" />
                            )}
                          </Box>
                        )}
                        <FormControlLabel
                          label={permission.permissionName}
                          style={{
                            width: 'fit-content',
                          }}
                          control={
                            <>
                              <Checkbox
                                checked={selectedPermission?.includes(String(permission.id))}
                                onChange={handleSelectPermission(String(permission.id))}
                              />
                            </>
                          }
                          componentsProps={{
                            typography: {
                              fontWeight: 'bold',
                              fontSize: '16px',
                              marginTop: '6px',
                              textTransform: 'uppercase',
                            },
                          }}
                        />
                        {hoveredItemId === String(permission.id) && (
                          <IconButton
                            disabled
                            color="error"
                            sx={{ ml: '-10px' }}
                            onClick={handleOpenDeletePermission(String(permission.id))}
                          >
                            <IconTrashFilled
                              style={{
                                cursor: 'pointer',
                                marginTop: 2,
                              }}
                              size={'20px'}
                            />
                          </IconButton>
                        )}
                      </Box>

                      {permission.children.length > 0 &&
                        closedList.includes(String(permission.id)) && (
                          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 8 }}>
                            {permission.children.map((child) => {
                              return (
                                <Fragment key={child.id}>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap="0"
                                    position="relative"
                                    paddingLeft={'32px'}
                                    onMouseEnter={() => setHoveredItemId(String(child.id))}
                                    onMouseLeave={() => setHoveredItemId('')}
                                  >
                                    {child.subChild && child.subChild.length > 0 && (
                                      <Box
                                        style={{ cursor: 'pointer' }}
                                        padding="0"
                                        left="0"
                                        marginTop="7px"
                                        width="24px"
                                        position="absolute"
                                        onClick={onChangeExpanded(String(child.id))}
                                      >
                                        {closedList.includes(String(child.id)) ? (
                                          <IconSquareRoundedMinusFilled
                                            height="24px"
                                            width="24px"
                                          />
                                        ) : (
                                          <IconSquareRoundedPlus height="24px" width="24px" />
                                        )}
                                      </Box>
                                    )}
                                    <FormControlLabel
                                      label={child.permissionName}
                                      control={
                                        <Checkbox
                                          checked={selectedPermission?.includes(String(child.id))}
                                          onChange={handleSelectPermission(String(child.id))}
                                        />
                                      }
                                      style={{
                                        width: 'fit-content',
                                      }}
                                      componentsProps={{
                                        typography: {
                                          fontSize: '14px',
                                          marginTop: '3px',
                                        },
                                      }}
                                    />
                                    {hoveredItemId === String(child.id) && (
                                      <IconButton
                                        disabled
                                        color="error"
                                        sx={{ ml: '-10px' }}
                                        onClick={handleOpenDeletePermission(String(child.id))}
                                      >
                                        <IconTrashFilled
                                          style={{
                                            cursor: 'pointer',
                                            marginTop: 2,
                                          }}
                                          size={'20px'}
                                        />
                                      </IconButton>
                                    )}
                                  </Box>
                                  {child.subChild &&
                                    child.subChild.length > 0 &&
                                    closedList.includes(String(child.id)) && (
                                      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 8 }}>
                                        {child.subChild.map((subItem) => {
                                          return (
                                            <Box
                                              key={subItem.id}
                                              onMouseEnter={() =>
                                                setHoveredItemId(String(subItem.id))
                                              }
                                              onMouseLeave={() => setHoveredItemId('')}
                                            >
                                              <FormControlLabel
                                                key={subItem.id}
                                                label={subItem.permissionName}
                                                control={
                                                  <Checkbox
                                                    checked={selectedPermission?.includes(
                                                      String(subItem.id),
                                                    )}
                                                    onChange={handleSelectPermission(
                                                      String(subItem.id),
                                                    )}
                                                  />
                                                }
                                                style={{
                                                  width: 'fit-content',
                                                }}
                                                componentsProps={{
                                                  typography: {
                                                    fontSize: '14px',
                                                    marginTop: '3px',
                                                  },
                                                }}
                                              />
                                              {hoveredItemId === String(subItem.id) && (
                                                <IconButton
                                                  disabled
                                                  color="error"
                                                  sx={{ ml: '-10px' }}
                                                  onClick={handleOpenDeletePermission(
                                                    String(subItem.id),
                                                  )}
                                                >
                                                  <IconTrashFilled
                                                    style={{
                                                      cursor: 'pointer',
                                                      marginTop: 2,
                                                    }}
                                                    size={'20px'}
                                                  />
                                                </IconButton>
                                              )}
                                            </Box>
                                          );
                                        })}
                                      </Box>
                                    )}
                                </Fragment>
                              );
                            })}
                          </Box>
                        )}
                    </Box>
                  </Fragment>
                );
              })}
            </Box>
          </BaseCard>
        </Stack>
        <PermissionModal
          data={formPermissionData}
          onChange={handleChangeCreatePermission}
          isOpen={isOpenCreatePermission}
          onClose={onCloseCreatePermission}
          onSubmit={handleCreatePermissionSubmit}
          listPermission={generatePermissionList}
        />
      </Box>
      <ConfirmModal
        isOpen={isOpenDeletePermission}
        onClose={onCloseDeletePermission}
        onSubmit={handleDeletePermission}
        message="delete-permission"
      />
      <AssignPermissionByRoleModal
        data={formAssignByRoleData}
        onChange={handleChangeAssignPermissionByRole}
        isOpen={isOpenAssignByRolePermission}
        onClose={onCloseAssignByRolePermission}
        onSubmit={handleAssignByRoleSubmit}
        listRole={listRole}
      />
    </PageContainer>
  );
}

export default EmployeePermissionContainer;
