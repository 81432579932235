import { Alert, AlertColor, Slide, SlideProps, Snackbar } from '@mui/material';
import React from 'react';
import { createPortal } from 'react-dom';
import { isMobile, isSuccessCode } from 'utils/common';

type Props = {
  status: AlertColor;
  message?: string;
  code?: number;
  duration?: number;
  isOpen?: boolean;
  onClose?: () => void;
};

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

function Toast({
  status,
  message = '',
  code = -1,
  duration = 5000,
  isOpen = false,
  onClose,
}: Props) {
  return createPortal(
    <>
      <Snackbar
        open={isOpen && message?.length > 0}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={duration}
        onClose={onClose}
        TransitionComponent={SlideTransition}
      >
        <Alert
          severity={status}
          variant="filled"
          sx={{ width: '100%', fontSize: isMobile ? '12px' : '16px' }}
          onClose={onClose}
        >
          {`${!isSuccessCode(code) ? `${message} (${code})` : message}`}
        </Alert>
      </Snackbar>
    </>,
    document.body,
  );
}

export default React.memo(Toast);
