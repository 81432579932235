import { CUSTOM_JOI_MESSAGE } from 'constants/joi';
import Joi from 'joi';

export const createSchema = (obj: Joi.PartialSchemaMap<any>) => {
  return Joi.object().options({ abortEarly: false }).keys(obj);
};

export const validateSchema = (schema: Joi.ObjectSchema<any>, payload: any) => {
  const res = schema.validate(payload, { messages: CUSTOM_JOI_MESSAGE });
  const keys = Object.keys(payload);
  const errorMessage: Record<string, string> = {};
  keys.forEach((key) => {
    errorMessage[key] = '';
  });
  if (res.error) {
    res.error.details.forEach((error) => {
      errorMessage[String(error.context?.key)] = error.message;
    });

    return {
      isError: true,
      errorMessage: errorMessage as any,
    };
  }

  return {
    isError: false,
    errorMessage: errorMessage as any,
  };
};
