import { CommonReq } from 'types/common.type';
import { centralGW } from './axios.service';
import { PermissionCreateFormType } from 'types/permission.type';

export const getListFEPermissions = async () => {
  return centralGW.get('/api/v1/auth/fe-permissions', {
    isDisableToast: true,
  });
};

export const createFEPermission = async (payload: PermissionCreateFormType) => {
  const { permissionName, permissionKey, permissionParentId, isActive } = payload;

  return centralGW.post(`/api/v1/auth/fe-permission`, {
    permissionName,
    permissionKey,
    ...(permissionParentId ? { permissionParentId } : {}),
    isActive,
  });
};

export const deleteFEPermission = async (payload: CommonReq) => {
  const { fePermissionId } = payload;

  return centralGW.delete(`/api/v1/auth/fe-permission/${fePermissionId}`);
};
