import React from 'react';

import { Card, CardHeader, CardContent, Divider } from '@mui/material';
import { useSelector } from 'store/Store';
import { customizerSelector } from 'store/customizer';

type Props = {
  title: string;
  children: JSX.Element | JSX.Element[];
};

function BaseCard({ title, children }: Props) {
  const customizer = useSelector(customizerSelector);

  return (
    <Card
      sx={{ padding: 0 }}
      elevation={customizer.isCardShadow ? 9 : 0}
      variant={!customizer.isCardShadow ? 'outlined' : undefined}
    >
      <CardHeader title={title} />
      <Divider />
      <CardContent>{children}</CardContent>
    </Card>
  );
}

export default React.memo(BaseCard);
