import React, { useRef } from 'react';
import DialogWrapper from 'components/dialog/DialogWrapper';
import { useTranslation } from 'react-i18next';
import { ModalActionType } from 'types/common.type';
import PermissionForm from 'components/forms/PermissionForm';
import { PermissionCreateFormType } from 'types/permission.type';

interface Props extends ModalActionType {
  data: PermissionCreateFormType;
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  listPermission?: any[];
}

const PermissionModal = ({ data, isOpen, listPermission, onClose, onChange, onSubmit }: Props) => {
  const { t } = useTranslation();
  const submitRef = useRef<{ handleSubmitPermission: () => void }>();

  const handleSubmitPermission = () => {
    submitRef.current?.handleSubmitPermission();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <DialogWrapper
      title={String(t('create-permission'))}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmitPermission}
      isFullWidth={false}
    >
      <PermissionForm
        ref={submitRef}
        data={data}
        onChange={onChange}
        onSubmit={onSubmit}
        listPermission={listPermission}
      />
    </DialogWrapper>
  );
};

export default React.memo(PermissionModal);
