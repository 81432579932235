/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ComponentType } from 'react';

import CustomFormLabel from 'components/theme-elements/CustomFormLabel';
import CustomOutlinedInput from 'components/theme-elements/CustomOutlinedInput';
import { useTranslation } from 'react-i18next';

import { Box, InputAdornment } from '@mui/material';
import { TablerIconsProps } from '@tabler/icons-react';

type Props = {
  isRequired?: boolean;
  Icon?: ComponentType<TablerIconsProps>;
  onChange?: (newValue: string) => void;
  errorMessage?: string;
  value?: string;
  title?: string;
  placeholder?: string;
  isDisable?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
};

const TextInput = ({
  isRequired = false,
  isDisable = false,
  onChange,
  errorMessage = '',
  value = '',
  title = '',
  placeholder = '',
  Icon,
  maxLength = 30,
  autoFocus = false,
}: Props) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <Box width="100%">
      {title && (
        <CustomFormLabel className={`${isRequired ? 'required' : ''}`} sx={{ mt: '0px' }}>
          {t(title)}
        </CustomFormLabel>
      )}
      <CustomOutlinedInput
        onChange={handleChange}
        size={'small'}
        value={value}
        sx={{
          padding: '5px',
        }}
        startAdornment={
          <InputAdornment
            position="start"
            sx={{ paddingLeft: Icon ? '10px' : '', marginRight: '10px' }}
          >
            {Icon && <Icon size="20px" />}
          </InputAdornment>
        }
        placeholder={t(placeholder)}
        fullWidth
        error={Boolean(errorMessage)}
        errorMessage={t(`validate-message.${errorMessage}`)}
        isDisable={isDisable}
        inputProps={{
          maxLength: maxLength,
        }}
        autoFocus={autoFocus}
      />
    </Box>
  );
};

export default React.memo(TextInput);
