import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { JOI } from 'constants/joi';
import { createSchema, validateSchema } from 'utils/joi';

import { Grid } from '@mui/material';
import AutoComplete from 'components/shared/AutoComplete';
import { showData } from 'utils/common';
import { IconAccessible } from '@tabler/icons-react';

interface Props {
  data: any;
  onChange: (key: string, value: string) => void;
  onSubmit?: () => void;
  isDisable?: boolean;
  listRole?: any[];
}

const initError = {
  roleId: '',
};

const AssignPermissionByRoleForm = forwardRef(
  ({ data, onChange, onSubmit, listRole = [] }: Props, ref) => {
    const handleChange = (key: string) => (value: string) => {
      onChange(key, value);
    };

    const schema = createSchema({
      roleId: JOI.REQUIRE,
    });

    const [permissionError, setPermissionError] = useState(initError);

    const handleSubmitAssignPermissionByRole = () => {
      const resError = validateSchema(schema, data);
      setPermissionError(resError.errorMessage);
      if (!resError.isError) {
        onSubmit && onSubmit();
      }
    };

    useImperativeHandle(ref, () => ({
      handleSubmitAssignPermissionByRole,
    }));

    return (
      <Grid item xs={12}>
        <Grid container spacing={'12px'}>
          <Grid item xs={12}>
            <AutoComplete
              title={'Phân quyền theo vai trò'}
              options={listRole.map((role) => ({
                label: showData(String(role.roleName)),
                value: String(role.id),
              }))}
              Icon={IconAccessible}
              value={String(data.roleId)}
              placeHolder={'Chọn quyền cha...'}
              onChange={handleChange('roleId')}
              errorMessage={permissionError.roleId}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  },
);

export default React.memo(AssignPermissionByRoleForm);
